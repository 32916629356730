<template>
    <div class="load_pc">
        <div class="load_center">
            <h1>web端正在开发中，请手机扫码查看</h1>
            <img src="https://www.momentshwork.com/wechatQR.png" alt="">
        </div>

    </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
    methods: {
        updateFoote() {
            let wow = new WOW({
                boxClass: "xox", //需要执行动画的元素的 class
                animateClass: "animated", //animation.css 动画的 class
                offset: 50, //距离可视区域多少开始执行动画
                mobile: true, //是否在移动设备上执行动画
                live: true, //异步加载的内容是否有效
            });
            wow.init();
        },
    },


};
</script>
<style lang="less">
.load_pc {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #ffe001;

    .load_center {
        width: 1200px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            margin-top: 20px;
            width: 160px;
            height: auto;
        }
    }
}
</style>