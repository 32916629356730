
<template>
  <div class="app">
    <div class="casecenter">
      <!-- -->
      <div class="head_kv" ref="nav" v-show="!isload">
        <div class="img_kv hoh pulse" data-wow-duration="1.5s">
          <img :src="th_yesdata.headImg" v-if="!isPC_mobile" alt="" />
          <img :src="th_yesdata.pcHeadImg" v-if="isPC_mobile" alt="" />
        </div>
        <div class="head_title">
          <h1 class="big_title hoh slideInUp" data-wow-delay=".2s" data-wow-duration=".5s">
            {{ th_yesdata.headTitle }}
          </h1>
          <p class="head_time hoh slideInUp" data-wow-delay=".3s" data-wow-duration=".5s">
            {{ th_yesdata.headSep }}
          </p>
        </div>
      </div>
      <div class="case_copy wow slideInUp" v-show="!isload" v-if="th_yesdata.introduce || th_yesdata.entry">
        <div class="t_info" :class="{ 'targetFixed': isSticky }">
          <div class="client">
            <span>{{ th_yesdata.entry.Client }}</span>
          </div>
          <div class="artdir">
            <span>{{ th_yesdata.entry.ArtDirector }}</span>
          </div>
          <div class="Illustration">
            <span>{{ th_yesdata.entry.Illustration }}</span>
          </div>
          <div class="Designr">
            <span>{{ th_yesdata.entry.Designr }}</span>
          </div>

        </div>
        <div class="t_introduce" :class="{ active: isOpen }" v-if="th_yesdata.introduce">
          {{ th_yesdata.introduce }}
        </div>
        <div v-if="th_yesdata.introduce" class="unfold" @click="open_text">
          <p v-show="isOpen">展开更多</p>
          <p v-show="!isOpen">点击收起</p>
        </div>
      </div>
      <div class="f_imgshow" v-if="!isPC_mobile" :style="{ background: th_yesdata.bcolor }" v-show="!isload">
        <ul>
          <li class="slideInUp" data-wow-duration="1s" v-for="details in th_yesdata.details" :key="details.value"
            :style="{ marginBottom: details.marginBottom }" :class="{ wow: !details.isNoAnima }">
            <div class="segmentationbox" v-if="details.Ch_segmentation || details.EN_segmentation"
              :style="{ color: th_yesdata.background }">
              <ul class="segmentation" :style="{ borderColor: th_yesdata.borderColor }">
                <li>{{ details.Ch_segmentation }}</li>
                <li>{{ details.EN_segmentation }}</li>
              </ul>
            </div>
            <img :src="details.imgSrc" :style="{ width: details.width, marginLeft: details.marginLeft }" alt="" />
            <div class="segmentation_video" v-if="details.videoSrc">
              <video autoplay loop webkit-playsinline="true" x-webkit-airplay="true" playsinline muted data-inline-media
                x5-video-player-type="h5" x5-video-player-fullscreen="true" :poster="details.videoImg" preload="none">
                <source :src="details.videoSrc" type="video/mp4" />
              </video>
            </div>
            <img :src="th_yesdata.headVsrcimg" alt="">
          </li>
        </ul>
      </div>

      <div class="f_imgshow" v-if="isPC_mobile" v-show="!isload" :style="{ background: th_yesdata.bcolor }">
        <ul>
          <li class="slideInUp" data-wow-duration="1s" v-for="details in th_yesdata.details" :key="details.value"
            :style="{ marginBottom: details.marginBottom }" :class="{ wow: !details.isNoAnima, }">
            <div class="segmentationbox" v-if="details.Ch_segmentation || details.EN_segmentation"
              :style="{ color: th_yesdata.background }">
              <ul class="segmentation" :style="{ borderColor: th_yesdata.borderColor }">
                <li>{{ details.Ch_segmentation }}</li>
                <li>{{ details.EN_segmentation }}</li>
              </ul>
            </div>
            <img :src="details.imgSrc" :style="{ width: details.width, marginLeft: details.marginLeft }" alt="" />
            <div class="segmentation_video" v-if="details.videoSrc">
              <video autoplay loop webkit-playsinline="true" x-webkit-airplay="true" playsinline muted data-inline-media
                x5-video-player-type="h5" x5-video-player-fullscreen="true" :poster="details.videoImg" preload="none">
                <source :src="details.videoSrc" type="video/mp4" />
              </video>
            </div>
            <img :src="th_yesdata.headVsrcimg" alt="">
          </li>
        </ul>
      </div>
      <!-- <div class="foot_next" v-if="isPC_mobile">
        <div class="l_next hoh slideInUp"> <svg class="svg_img" xmlns="http://www.w3.org/2000/svg" width="61.207"
            height="8.485" viewBox="0 0 61.207 8.485">
            <g id="组_9" data-name="组 9" transform="translate(-92.293 -3096.222)">
              <line id="直线_19" data-name="直线 19" x1="60" transform="translate(93 3104)" fill="none" stroke="#000"
                stroke-linecap="round" stroke-width="1" />
              <line id="直线_20" data-name="直线 20" y2="10" transform="translate(100.071 3096.929) rotate(45)" fill="none"
                stroke="#000" stroke-linecap="round" stroke-width="1" />
            </g>
          </svg>
          上一个项目 </div>
        <div class="r_next hoh slideInUp"> 下一个项目<svg class="svg_img" xmlns="http://www.w3.org/2000/svg" width="61.207"
            height="8.485" viewBox="0 0 61.207 8.485">
            <g id="组_10" data-name="组 10" transform="translate(0.5 0.707)">
              <line id="直线_19" data-name="直线 19" x2="60" transform="translate(0 7.071)" fill="none" stroke="#000"
                stroke-linecap="round" stroke-width="1" />
              <line id="直线_20" data-name="直线 20" y1="10" transform="translate(60 7.071) rotate(135)" fill="none"
                stroke="#000" stroke-linecap="round" stroke-width="1" />
            </g>
          </svg>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  props: {
    isPC_mobile: Boolean
  },
  data() {
    return {
      isload: true,
      isOpen: true,
      th_data: {},
      th_yesdata: {},
      th_id: this.$route.query.id,
      th_name: this.$route.params.business,
      isSticky: false, //是否吸顶
      navHeight: 0, // nav的高度
      conScrollTop: 0, // 容器滚动条距离顶部高度
      thatOffsetTop: 0, // 当前组件距离顶部高度
      case_data: [
        {
          work: "ds",
          isAnimation: true,
          id: 0,
          headImg:
            "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/case_head_img.jpg",
          pcHeadImg: "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/pc_case_head_img.jpg",
          headTitle: "BE-KIND猫头海报",
          headSep: "Oct. 16. 2020",
          borderColor: "#333",
          bcolor: "#fff",
          entry: {
            Client: "Client：BE-KIND",
            ArtDirector: "Art Director：朱佳峥",
            Designr: "Designer：秀秀",
          },

          details: [
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details0.jpg",

            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details1.jpg",

            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details2.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details3.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details4.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details5.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details6.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details7.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details8.jpg",
              isNoAnima: true,

            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details9.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/beking_tamll/details10.jpg",
              isNoAnima: true,
            },
          ],
        },
        {
          work: "ds",
          isAnimation: true,
          id: 1,
          headImg:
            "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/case_head_img.jpg",
          pcHeadImg: "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/pc_case_head_img.jpg",
          headTitle: "高洁丝海岛奢宠纯棉",
          headSep: "Mar. 04. 2021",
          bcolor: "#050816",
          background: "#ffffff",
          entry: {
            Client: "Client：高洁丝",
            ArtDirector: "Account Executive：YAmizing",
            Designr: "Designer：Bobi",
          },
          details: [
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/details0.jpg",
              isNoAnima: true,

            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/details1.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/details2.jpg",
              isNoAnima: true,

            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/details3.jpg",
              isNoAnima: true,

            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/details4.jpg",
              isNoAnima: true,

            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/details5.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/details6.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/ds/work/kotex_platinum/details7.gif",
              isNoAnima: true,
            },
          ],
        },

        {
          work: "pp",
          id: 0,
          isAnimation: true,
          headImg:
            "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/case_head_img.jpg",
          pcHeadImg: "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/pc_case_head_img.jpg",
          headTitle: "养个蛙品牌视觉形象",
          headSep: "May. 28. 2018",
          bcolor: "#fff",
          entry: {
            Client: "Client：养个蛙",
            ArtDirector: "Art Director：马森山",
            Designr: "Designer：Life bobi",
          },
          details: [
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details0.gif",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details1.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details2.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details3.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details4.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details5.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details6.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details7.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details8.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details9.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details10.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details11.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/wawa_vis/details12.jpg",
            },
          ],
        },
        {
          work: "pp",
          id: 1,
        },
        {
          work: "pp",
          id: 2,
          isAnimation: true,
          headImg:
            "https://www.momentshwork.com/momentimg/pp/work/bugles_xpzsj/case_head_img.jpg",
          pcHeadImg: "https://www.momentshwork.com/momentimg/pp/work/bugles_xpzsj/pc_case_head_img.jpg",
          headTitle: "妙脆角新品主视觉",
          headSep: "Feb.03.2023",
          bcolor: "#fff",
          entry: {
            Client: "Client：奇多",
            ArtDirector: "Account Executive：Fiona",
            Designr: "Designer：Life Estrella 马森山",
          },
          details: [
            {
              videoSrc:
                "https://www.momentshwork.com/momentimg/pp/work/bugles_xpzsj/details0.mp4",
              headVsrcimg:
                "https://www.momentshwork.com/momentimg/pp/work/bugles_xpzsj/details0.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/bugles_xpzsj/details1.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/bugles_xpzsj/details2.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/bugles_xpzsj/details3.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/bugles_xpzsj/details4.jpg",
              marginBottom: "1.8%"
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/pp/work/bugles_xpzsj/details5.gif",
              marginBottom: "1.8%"
            },
          ],
        },
        {
          work: "lh",
          isAnimation: false,
          id: 0,
          headImg:
            "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/case_head_img.jpg",
          pcHeadImg: "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/pc_case_head_img.jpg",
          headTitle: "好奇金钻礼盒",
          headSep: "June. 04. 2019",
          entry: {
            Client: "Client：好奇",
            ArtDirector: "Account Executive：Finn",
            Designr: "Designer：Life",
          },
          details: [
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details0.jpg",
              isNoAnima: true,

            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details1.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details2.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details3.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details4.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details5.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details6.gif",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details7.jpg",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details8.gif",
              isNoAnima: true,
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/huggies_jzlh/details9.jpg",
              isNoAnima: true,
            },
          ],
        },
        {
          work: "lh",
          isAnimation: false,
          id: 1,
          headImg:
            "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/case_head_img.jpg?v=21321",
          pcHeadImg: "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/pc_case_head_img.jpg",
          headTitle: "乐事京东CNY礼盒",
          headSep: "September. 20. 2022",
          bcolor: "#ad0002",
          background: "#ffffff",
          entry: {
            Client: "Client：乐事",
            ArtDirector: "Account Executive：Fiona",
            Illustration: "Illustration：小齐",
            Designr: "Designer：Life",
          },
          details: [
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/details0.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/details1.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/details2.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/details3.gif",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/details4.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/details5.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/details6.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/details7.jpg",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cnylh/details8.jpg",
            },

          ],
        },
        {
          work: "lh",
          isAnimation: true,
          id: 2,
          headImg:
            "https://www.momentshwork.com/momentimg/lh/work/lays_cjxdlh/case_head_img.jpg",
          pcHeadImg: "https://www.momentshwork.com/momentimg/lh/work/lays_cjxdlh/pc_case_head_img.jpg",
          headTitle: "乐事春季限定礼盒",
          headSep: "January. 09. 2023",
          background: "#ffffff",
          entry: {
            Client: "Client：乐事",
            ArtDirector: "Account Executive：Fiona",
            Designr: "Designer：Life 小陈",
          },
          details: [
            {
              videoSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cjxdlh/details0.mp4",
              headVsrcimg:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cjxdlh/details0.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cjxdlh/details1.jpg",
              marginBottom: "1.8%",
            },
            {

              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cjxdlh/details2.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cjxdlh/details3.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/lh/work/lays_cjxdlh/details4.jpg",
            },
          ],
        },
        {
          work: "syps",
          id: 0,
          isAnimation: true,
          headImg:
            "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/case_head_img.jpg",
          pcHeadImg: "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/pc_case_head_img.jpg",
          headTitle: "禾泱泱海苔碎拌饭料",
          headSep: "Oct. 23. 2020",
          bcolor: "#fff",
          entry: {
            Client: "Client：禾泱泱",
            ArtDirector: "Art Director：朱佳峥",
            Designr: "Account Executive：puya 佳月",
          },
          details: [
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/details0.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/details1.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/details2.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/details3.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/details4.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/details5.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/details6.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/details7.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_htsbfl/details8.jpg",
            },
          ],
        },
        {
          work: "syps",
          id: 1,
          isAnimation: true,
          headImg:
            "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/case_head_img.jpg",
          pcHeadImg: "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/pc_case_head_img.jpg",
          headTitle: "禾泱泱夹心米饼",
          headSep: "Dec. 08. 2021",
          bcolor: "#fff",
          entry: {
            Client: "Client：禾泱泱",
            ArtDirector: "Art Director：朱佳峥",
            Designr: "Account Executive：puya 佳月",
          },
          details: [
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/details0.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/details1.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/details2.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/details3.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/details4.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/details5.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/details6.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/details7.jpg",
              marginBottom: "1.8%",
            },
            {
              imgSrc:
                "https://www.momentshwork.com/momentimg/syps/work/rivsea_jxmb/details8.jpg",
            },
          ],
        },
      ],
    };
  },
  methods: {
    open_text() {
      this.isOpen = !this.isOpen;
    },
    loading() {
      let that = this;
      let videoDom = document.querySelector("video");
      console.log(videoDom);
      document.onreadystatechange = subSomething; //当页面加载状态改变的时候执行这个方法.
      function subSomething() {
        if (document.readyState == "complete") {
          if (videoDom == null) {
          } else {
            if (videoDom.paused) {
              videoDom.play();
            }
          }
          // setTimeout(() => {
          //   document
          //     .querySelector("html")
          //     .setAttribute("style", "overflow:visible; position: static");
          // }, 500);
          setTimeout(() => {
            that.isload = false;
            let hoh = new WOW({
              boxClass: "hoh",
              animateClass: "animated",
              offset: 0,
              mobile: true,
              live: false,
            });
            hoh.init();
            let wow = new WOW({
              boxClass: "wow",
              animateClass: "animated",
              offset: 300,
              mobile: that.th_yesdata.isAnimation,
              live: false,
            });
            wow.init();


          }, 500);
        }
      }
      subSomething();
    },
    initSticky() {
      // 
      this.$nextTick(() => {
        window.addEventListener("scroll", (e) => {
          this.thatOffsetTop = this.getScrollTop(); // 滚动条距离顶部高度
          if (this.thatOffsetTop >= document.querySelector(".head_kv").clientHeight) {
            this.isSticky = true;
          } else {
            this.isSticky = false;
          }
        });
      });
      // console.log("this.navHeight :", this.navHeight);

    },
    getScrollTop() {
      var scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      return scrollTop;
    },
  },
  mounted() {
    // 监听页面滚动事件

    let that = this;

    document.querySelector("#app").style.cssText = "background:none";
    this.$parent.navAnimation = true;
    this.th_data = this.case_data.filter((item) => {
      if (item.work == this.th_name) {
        return item;
      }
    });

    this.th_yesdata = this.th_data[this.th_id];
    that.$nextTick(() => {
      that.loading();
    });
  },
  beforeCreate() {
    document
      .querySelector("html")
    // .setAttribute("style", "overflow:hidden; position: fixed");
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll) // 销毁监听滑动事件
  }
};
</script>
<style lang="less" scoped>
.app {
  padding-top: 155px;
  margin-bottom: -1px;



  .casecenter {
    width: 100%;
    min-height: 100vh;

    .head_kv {
      width: 100%;
      position: relative;
      overflow: hidden;

      .img_kv {
        width: 100%;
        height: auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .head_title {
        padding: 0 45px;
        width: 100%;
        position: absolute;
        bottom: 77px;
        box-sizing: border-box;

        .big_title {
          font-size: 50px;
          font-family: harmonyMedium;
          color: #fff;
        }

        .head_time {
          margin-top: 10px;
          font-size: 24px;
          font-family: harmonyLight;
          color: #fff;
        }
      }
    }

    .case_copy {
      background-color: #fff;
      padding: 65px 45px 80px 45px;
      box-sizing: border-box;

      .t_info {
        margin-bottom: 65px;
        font-family: harmonyLight;
        font-size: 28px;
        line-height: 45px;
      }

      .t_introduce {
        height: auto;
        position: relative;
        overflow: hidden;
        font-family: harmonyLight;
        font-size: 28px;
        line-height: 45px;
        text-align: justify;
      }

      .active {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }

      .unfold {
        font-family: harmonyLight;
        text-align: right;
        border-bottom: 1px solid #888888;
        height: 128px;

        p {
          font-size: 22px;
          line-height: 204px;
        }
      }
    }

    .case_segmentation {
      width: 100%;
      height: 150px;
    }

    .f_imgshow {
      overflow: hidden;

      ul {
        li {
          overflow: hidden;
          position: relative;
          display: flex;
          align-items: center;

          img {
            width: 1587.2px;
            height: auto;
            object-fit: cover;
            position: relative;
            left: 50%;
            margin-left: -793.6px;
          }

          .segmentationbox {
            padding: 50px 40px;
            box-sizing: border-box;

            .segmentation {
              margin: 0 auto;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid rgba(255, 255, 255, 0.3);
              font-family: harmonyLight;

              li {
                font-size: 28px;
              }

              :nth-child(2) {
                font-size: 24px;
              }
            }


          }

          .segmentation_video {
            position: relative;
            width: 100%;

            video {
              display: block;
              width: 1587.2px;
              height: auto;
              object-fit: cover;
              position: relative;
              left: 50%;
              margin-left: -793.6px;
            }

            img {
              display: block;
              width: 100%;
            }

            .video_btn {
              width: 200px;
              height: 200px;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              background-color: pink;
            }
          }
        }

        :nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>