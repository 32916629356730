<template>
  <div class="foot_box" id="info">

    <dl class="center xox slideInUp" v-if="!isPc">
      <dt class="contactus">联系我们</dt>
      <dd class="big_txt">mss@sh-tiktok.com.cn</dd>
      <dd>虹口区汶水东路29号榕辉大厦1203</dd>
      <dd>上海 中国</dd>
    </dl>
    <div class="foot_text xox slideInUp" v-if="!isPc">
      COPYRIGHT © MOMENT INC. ALL RIGHTS RESERVED.
    </div>
    <div class="pc_l_logo xox slideInUp" v-if="isPc">

      <img src="@/img/moment_studio_t.svg" alt="">
    </div>
    <dl class="center xox slideInUp" v-if="isPc">
      <dt class="contactus">联系我们</dt>
      <dd class="big_txt">mss@sh-tiktok.com.cn</dd>
      <dd>COPYRIGHT © MOMENT INC. ALL RIGHTS RESERVED.</dd>
    </dl>
    <div class="foot_text xox slideInUp" v-if="isPc">
      <p>Moment（秒珍）广告传播有限公司</p>
      <p>汶水路333号榕辉大厦1203</p>
      <p>上海 中国</p>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      isPc: false,
    }
  },
  methods: {
    updateFoote() {
      let wow = new WOW({
        boxClass: "xox", //需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 50, //距离可视区域多少开始执行动画
        mobile: true, //是否在移动设备上执行动画
        live: true, //异步加载的内容是否有效
      });
      wow.init();
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted() {
    let that = this;
    if (that._isMobile()) {
      that.isPc = false

    } else {
      that.isPc = true
    }
    that.$nextTick(() => {
      that.updateFoote();
    });
  },
  watch: {
    $route(to, from) {
      let that = this;
      that.updateFoote();
    },
  },
};
</script>
<style lang="less" scoped>
.foot_box {
  width: 100%;
  background-color: #000000;
  overflow: hidden;
  padding: 0 45px;
  box-sizing: border-box;

  .center {
    margin-top: 130px;

    dt {
      color: #fae14b;
      font-size: 50px;
      font-weight: 600;
      letter-spacing: 2px;
      margin-bottom: 20px;
      font-family: harmonyMedium;
    }

    dd {
      color: #686868;
      font-size: 28px;
      margin-bottom: 10px;
      font-family: harmonyLight;
    }

    .big_txt {
      color: #c7c7c7;
      font-size: 50px;
      margin-bottom: 60px;
    }

    .contactus {
      margin-bottom: 0;
    }
  }

  .foot_text {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #555555;
    margin-top: 100px;
    margin-bottom: 50px;
    color: #9a9a9a;
    font-family: harmonyLight;
    font-size: 15px;
  }
}

.foot_anime {
  opacity: 0;
  transform: translateY(-84px);
  transition: opacity 0.9s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
}
</style>