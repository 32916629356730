<template>
  <div id="app">
    <Header :class="{ active_t: navAnimation }"></Header>
    <!-- 路由组件出口 -->
    <router-view :key="key" :isPC_mobile="isPc"></router-view>
    <Footer></Footer>
    <!-- <Loadpc v-if="isPc"></Loadpc> -->
  </div>
</template>
<script>
import Header from "./components/Header";
import Loadpc from "./components/Loadpc";
import Footer from "./components/Footer";
import "animate.css";
export default {
  data() {
    return {
      key: "",
      SkipSwitchName: "",
      navAnimation: false,
      isPc: false,
      navH: "",
    };
  },
  components: {
    Header,
    Footer,
    Loadpc
  },
  computed: {},
  mounted() {

    if (this._isMobile()) {
      this.isPc = false

    } else {
      this.isPc = true
    }
  },

  methods: {
    getInfo() {
      this.key = new Date().getTime();
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }

  },
  watch: {
    $route(to, from) {
      this.getInfo();
    },
  },
};
</script>
<style lang="less">
.fadeup_anime {

  .app,
  .foot_box {
    opacity: 0;
    transform: translateY(-200px);
    transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.active_t {
  .black_box {
    top: 0 !important;
  }

  .r_nav_btn {
    span {
      background-color: #fff !important;
    }
  }


  .top_head {
    .l_logo {
      :nth-child(1) {
        display: none !important;
      }

      :nth-child(2) {
        display: block !important;
      }
    }
  }
}

@media screen and (min-width: 800px) {

  html {
    overflow-y: overlay;
  }

  /* 定义相对于左上角的定位 */
  .lt(@left: 0, @top: 0) {
    position: absolute;
    left: @left / @vw;
    top: @top / @vw;
  }

  /* 定义相对于左下角的定位 */
  .lb(@left: 0, @bottom: 0) {
    position: absolute;
    left: @left / @vw;
    bottom: @bottom / @vw;
  }

  /* 定义相对于右上角的定位 */
  .rt(@right: 0, @top: 0) {
    position: absolute;
    right: @right / @vw;
    top: @top / @vw;
  }

  /* 定义相对于右下角的定位 */
  .rb(@right: 0, @bottom: 0) {
    position: absolute;
    right: @right / @vw;
    bottom: @bottom / @vw;
  }

  /* 定义水平居中的定位 */
  .cl(@width: 0) {
    position: absolute;
    left: 50%;
    margin-left: -@width / 2 / @vw;
  }

  /* 定义垂直居中的定位 */
  .ct(@height: 0) {
    position: absolute;
    top: 50%;
    margin-top: -@height / 2 / @vw;
  }



  @vw: 1920/100vw;

  * {
    padding: 0;
    margin: 0;
  }

  .setwh(@width: 0, @height: 0) {
    width: @width / @vw;
    height: @height / @vw;
  }


  #app {
    width: 1920 / @vw;
    position: relative;
    overflow: hidden;

    .app {
      padding-top: 54 / @vw;
      background-color: #ffe000;

      .centerbox {
        padding: 1vw 250 / @vw 130 / @vw ;

        .top_head {
          .us_title {
            p {
              font-size: 18 / @vw;
              margin-top: 0;
            }
          }

          .divider {
            margin-top: 20 / @vw;
          }

          .ch_title {
            p {
              font-size: 40 / @vw;
              margin-top: 0.3vw;
            }
          }

          .copy_text {


            p {
              margin-top: 5 / @vw;
              font-size: 16 / @vw;
            }
          }
        }

        .listbox {
          display: grid;
          grid-template-columns: repeat(12, minmax(0px, 1fr));
          column-gap: 20px;

          li {
            grid-column: span 4 / span 4;
            margin-right: 40px;

            .b_title {
              margin-top: 13 / @vw;

              .big_title {
                font-size: 25 / @vw;
              }

              .time_text {
                margin-top: 11 / @vw;

                p {
                  font-size: 14 / @vw;
                }

                .link_btn {
                  .setwh(24, 24);

                  img {
                    width: 100%;
                    height: auto;
                  }
                }
              }
            }

            .topimg {
              width: 100%;
              height: auto;
              transition: all 0.3s;
              cursor: pointer;
              position: relative;

              .top_m {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                color: #fff;
                opacity: 1;
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h1 {
                  font-size: 2vw;
                  font-family: harmonyMedium;
                  letter-spacing: 0.1em;

                }

                p {
                  font-family: harmonyLight;
                  font-size: 0.8vw;
                  margin-bottom: 0.6vw;
                }
              }

              img {
                transition: all 1s;
              }

              &:hover {
                border-radius: 20px;

                .top_m {
                  display: flex;
                  background-color: #000;
                  opacity: 0.8;
                }

                img {
                  transform: scale(1.2);
                  transition: all 1s;

                }
              }
            }
          }

          li:nth-child(1) {
            grid-column: span 4 / span 4;
            margin-right: 40px;
          }

          li:nth-child(2) {
            grid-column: 9 / span 4;
            margin-left: -20px;
            margin-top: 304px;
          }

          li:nth-child(3) {
            grid-column: 4 / span 4;
            margin-left: 35px;
            margin-top: -121px;
          }

          li:nth-child(4) {
            grid-column: 7 / span 4;
            margin-right: -20px;
            margin-top: 160px;
          }

        }

      }

      .casecenter {
        background-color: #ffe000;

        .head_kv {
          .img_kv {
            img {
              .setwh(1920, 691);
            }
          }

          .head_title {
            left: 360 / @vw;
            bottom: 55 / @vw;
            padding: 0;

            .big_title {
              font-size: 45 / @vw;
            }

            p {
              font-size: 10 / @vw;
            }
          }
        }

        .case_copy {
          background: unset;
          padding: 115 / @vw 38 / @vw 0 360 / @vw;

          .t_info {
            font-size: 14 / @vw;
            line-height: 30 / @vw;
          }

          .targetFixed {
            position: fixed;
            top: 160 / @vw;
          }
        }

        .f_imgshow {
          width: 100%;
          margin-top: 115 / @vw;
          background-color: #ffe000;

          ul {
            width: 100%;

            li {
              width: 100%;
              display: block;
              margin-bottom: unset;

              img {
                width: 100%;
                margin: 0 auto;
                position: unset;
              }

              .segmentation_video {
                width: 100%;

                video {
                  display: block;
                  width: 100%;
                  height: auto;
                  position: unset;
                  left: 0;
                  margin-left: 0;
                }
              }
            }
          }

        }

        .f_imgshow::-webkit-scrollbar {
          display: none;
          /* Chrome Safari */
        }


        .foot_next {
          width: 1200 / @vw;
          margin: 0 auto;
          margin-top: 3.20833333vw;
          padding-top: 0.7166667vw;
          padding-bottom: 2.42708333vw;
          display: flex;
          justify-content: space-between;

          .l_next {
            cursor: pointer;
            font-size: 15 / @vw;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: harmonyLight;
            position: relative;
            line-height: 32 / @vw;
            padding: 0 10 / @vw;
            z-index: 3;
            color: #000;
            visibility: hidden;
            transition: all 0.3s;

            .svg_img {
              width: 2.5625vw;
              margin-right: 10 / @vw;
              margin-top: -0.30416667vw;
              transition: all 0.3s;
            }

            &::before {
              content: '';
              width: 0;
              height: 100%;
              background: #000;
              position: absolute;
              z-index: -1;
              right: 0;
              top: 0;
              -webkit-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;

            }

            &:hover::before {
              width: 100%;
            }

            &:hover .svg_img line {
              stroke: #ffe000;
            }

            &:hover {
              color: #ffe000;
            }

          }



          .r_next {
            cursor: pointer;
            font-size: 15 / @vw;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: harmonyLight;
            position: relative;
            line-height: 32 / @vw;
            padding: 0 10 / @vw;
            z-index: 3;
            visibility: hidden;
            transition: all 0.3s;

            .svg_img {
              width: 2.5625vw;
              margin-left: 10 / @vw;
              margin-top: -0.30416667vw;
              transition: all 0.3s;
            }

            &::before {
              content: '';
              width: 0;
              height: 100%;
              background: #000;
              position: absolute;
              z-index: -1;
              left: 0;
              top: 0;
              -webkit-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;

            }

            &:hover::before {
              width: 100%;
            }

            &:hover .svg_img line {
              stroke: #ffe000;
            }

            &:hover {
              color: #ffe000;
            }

          }
        }
      }
    }

    .nav_top {
      max-width: 100%;
      min-width: 1200px;
      padding: 0 130 / @vw;
      height: 55 / @vw;

      .black_box {
        height: 55 / @vw;
      }

      .top_head {
        height: 55 / @vw;

        .l_logo {
          width: 35 / @vw;
          height: auto;

          img {
            width: 100%;
            height: auto;
          }
        }

        .r_nav_btn {
          width: 30 / @vw;
          height: auto;
          cursor: pointer;
          overflow: hidden;

          &:hover {}

          span {
            height: 2 / @vw;
            margin-bottom: 10 / @vw;
          }

          span:last-child {
            margin-bottom: 0;
          }

          .t_active {
            transform: translateY(6 / @vw) rotate(45deg);
          }

          .b_active {
            transform: translateY(-6 / @vw) rotate(-45deg);
          }
        }
      }

      .an_nav {
        padding: 0 14.041667vw;
        padding-top: 8.041667vw;
        z-index: 999;

        ul {
          li {
            font-family: harmonyBold;
            letter-spacing: 0.1vw;
            color: #646464;

            span {
              font-size: 12.15 / @vw;
              left: 3.5vw;
              top: 1.4vw;

            }

            font-size:21.86 / @vw;
            height: 40 / @vw;
            line-height: 40 / @vw;
          }

          .zhuye_list {
            height: 80 / @vw;
            line-height: 80 / @vw;
            font-size: 25/ @vw;

          }

          > ::after {

            background-color: #3e3e3e;
          }

          .gongzuo_list {
            height: 80 / @vw;
            line-height: 80 / @vw;
            font-size: 25/ @vw;

            .gz_open {
              li {
                font-family: harmonyLight;
              }
            }

            p {
              line-height: 80 / @vw;
            }
          }

          .td_list {
            height: 80 / @vw;
            line-height: 80 / @vw;
            font-size: 25/ @vw;
          }

          .lx_list {
            height: 80 / @vw;
            line-height: 80 / @vw;
            font-size: 25/ @vw;
          }

          .active {
            height: 17.5vw;
          }
        }
      }

      .foot_text {
        span {
          font-size: 8 / @vw;
        }
      }
    }

    .active {
      height: 100%;
    }

    .foot_box {
      display: block;
      padding: 0 360 / @vw;
      display: flex;
      justify-content: space-between;
      padding-top: 30/ @vw;

      .pc_l_logo {
        width: 5.202083vw;
        height: auto;

        img {
          width: 100%;
          height: auto;
        }
      }

      .center {
        display: block;
        margin-top: 0;
        margin-left: 19.302083vw;

        .contactus {
          font-size: 16 / @vw;
        }

        .big_txt {
          font-size: 24 / @vw;
          margin-top: 0.5066667vw;
          font-family: harmonyLight;
        }

        dd {
          font-size: 8 / @vw;
          margin-bottom: 1.21vw;
          font-family: harmonyLight;

        }
      }

      .foot_text {
        font-size: 12 / @vw;
        color: #757575;
        margin-top: 0;
        border-top: unset;
        line-height: unset;
        width: auto;

        p {
          margin-bottom: 0.3375vw;
          font-family: harmonyLight;
        }
      }
    }

    .head {
      height: 160vh;
      overflow: hidden;
      position: relative;

      .anime_one {
        overflow: visible;
      }

      .head_biglog_img {
        margin: unset;
        width: 38.0625vw;
        position: absolute;
        top: 46%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          width: 100%;
          height: auto;
        }


      }



      .pc_head_us_img {
        display: flex;
        top: 0vw;
        left: 0vw;
        font-size: 4.6vw;
        font-family: harmonyBlack;
        -webkit-text-stroke: 2 / @vw #fff;
        -webkit-text-fill-color: transparent;

        .warp {
          width: 1920 / @vw;
          height: 176 / @vw;
          overflow: hidden;

          ul {
            list-style: none;
            padding: 0;
            margin: 0 auto;

            &.ul-item {
              display: flex;

              h1 {
                margin-right: 2.520833vw;
              }

            }
          }
        }


      }

      .head_ch_img {
        z-index: 2;
        position: absolute;
        right: 25.3125vw;
        top: 4.5vw;
        font-size: 84 / @vw;
        font-family: "harmonyBold";
        letter-spacing: 0.3vw;

        p {
          line-height: 5vw;
        }
      }

      .head_mintext_img {
        position: absolute;
        right: 36.6vw;
        bottom: 7.989583vw;
        font-weight: 600;
        font-size: 0.65104167vw;

        p {
          letter-spacing: 0.1vw;
          line-height: 1vw;
        }
      }

      .head_down_img {
        .setwh(23, 28);
        .cl(23);
        bottom: 100 / @vw;

        img {
          width: 100%;
          height: auto;
        }
      }

      .head_foot_text {
        width: 670 / @vw;
        .cl(672);
        bottom: -336 / @vw;
        font-size: 22 / @vw;
        font-weight: 500;
        line-height: 1.702083vw;
        letter-spacing: 0.1vw;
      }

      .head_logo_01 {
        .setwh(197, 34);
        .lb(388, 433);

        img {
          width: 100%;
          height: auto;
        }
      }

      .head_logo_02 {
        .setwh(190, 61);
        .lb(1356, 264);

        img {
          width: 100%;
          height: auto;
        }
      }

      .head_logo_03 {
        .setwh(187, 56);
        .lb(393, 256);

        img {
          width: 100%;
          height: auto;
        }
      }

      .head_logo_04 {
        .setwh(125, 69);
        .lb(1059, 415);

        img {
          width: 100%;
          height: auto;
        }
      }

      .head_logo_05 {
        .setwh(173, 72);
        .lb(715, 250);

        img {
          width: 100%;
          height: auto;
        }
      }

      .head_logo_06 {
        .setwh(155, 48);
        .lb(1045, 254);

        img {
          width: 100%;
          height: auto;
        }
      }

      .head_logo_08 {
        .setwh(248, 57);
        .lb(662, 430);

        img {
          width: 100%;
          height: auto;
        }
      }

      .head_logo_09 {
        .setwh(190, 61);
        .lb(1345, 414);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .work_list {
      width: 100%;

      ul {
        li {
          width: 100%;
          .setwh(1920, 870);

          img {
            width: 100%;
            height: auto;
          }

          .golink_img {
            .setwh(51, 51);
            top: 434 / @vw;
            left: 19.3vw;

            img {
              width: 100%;
              height: auto;
            }
          }

          .t_title {
            top: 70 / @vw;
            left: 360 / @vw;

            h3 {
              font-size: 47.64 / @vw;
            }

            h1 {
              font-size: 70.43 / @vw;
            }

            ._icon {
              margin: 30 / @vw auto 0 30 / @vw 0;
            }
          }

        }
      }
    }

  }
}

@media screen and (min-width: 1440px) {
  #app {
    .head {
      height: 125vh;
    }
  }
}
</style>
