<template>
  <div class="app">
    <div class="error wow slideInUp" v-if="!t_Title[th_id].code">
      <div class="top_head" v-show="!isload">
        <div class="us_title">
          <p class="wow slideInUp" data-wow-duration=".3s">
            {{ t_Title[th_id].us_name }}
          </p>
        </div>
        <div class="ch_title wow slideInUp" data-wow-delay=".2s" data-wow-duration=".3s">
          <p>
            {{ t_Title[th_id].name }}
          </p>
        </div>
        <div class="copy_text wow slideInUp" data-wow-delay=".5s" data-wow-duration=".3s">
          <p>{{ t_Title[th_id].min_title }}</p>
        </div>
        <div class="divider wow slideInUp" data-wow-delay=".8s" data-wow-duration=".3s"></div>
      </div>
      <ul class="listbox" v-show="!isload">
        <li v-for="workData in t_Title[th_id].datalist" :key="workData.value">
          <div class="topimg wow slideInUp" data-wow-delay=".3s" data-wow-duration="1.5s">
            <img :src="workData.imgUrl" alt="" />
          </div>
          <div class="b_title wow slideInUp">
            <h1 class="big_title">{{ workData.copy_title }}</h1>
            <div class="time_text">
              <p>{{ workData.copy_time }}</p>
              <div class="link_btn">
                <img src="@/img/ll_link_btn.png" alt="" />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="centerbox" v-if="t_Title[th_id].code">
      <div class="top_head" v-show="!isload">
        <div class="us_title">
          <p class="wow slideInUp" data-wow-duration=".3s">
            {{ t_Title[th_id].us_name }}
          </p>
        </div>
        <div class="ch_title wow slideInUp" data-wow-delay=".2s" data-wow-duration=".3s">
          <p>
            {{ t_Title[th_id].name }}
          </p>
        </div>
        <div class="copy_text wow slideInUp" data-wow-delay=".5s" data-wow-duration=".3s">
          <p>{{ t_Title[th_id].min_title }}</p>
        </div>
        <div class="divider wow slideInUp" data-wow-delay=".8s" data-wow-duration=".3s"></div>
      </div>
      <ul class="listbox" v-show="!isload">
        <li v-for="workData in t_Title[th_id].datalist" :key="workData.value" @click="goTolink(workData)">
          <div class="topimg wow slideInUp" data-wow-delay=".3s" data-wow-duration="1.5s">
            <img :src="workData.imgUrl" alt="" />
            <div class="top_m">
              <p>{{ workData.copy_time }}</p>
              <h1>{{ workData.copy_title }}</h1>

            </div>
          </div>
          <div class="b_title wow slideInUp">
            <h1 class="big_title">{{ workData.copy_title }}</h1>
            <div class="time_text">
              <p>{{ workData.copy_time }}</p>
              <div class="link_btn">
                <img src="@/img/ll_link_btn.png" alt="" />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  props: {
    isPC_mobile: Boolean
  },
  data() {
    return {
      th_id: this.$route.query.id,
      isload: true,
      li_lens: 'li_lens1',
      t_Title: [
        {
          code: true,
          name: "电商设计",
          us_name: "E-COMMERCE",
          min_title: "从消费者的角度去思考，才能做出最吸引客户的设计",
          datalist: [
            {
              imgUrl: "https://www.momentshwork.com/momentimg/ds/work/img3.jpg",
              copy_title: "乐事京东CNY礼盒详情页(更新中)",
              copy_time: "Nov.07.2022",
              id: 2,
              isState: true
            },
            {
              imgUrl: "https://www.momentshwork.com/momentimg/ds/work/img2.jpg",
              copy_title: "高洁丝海岛奢宠纯棉",
              copy_time: "Mar. 04. 2021",
              id: 1,
              isState: false
            },
            {
              imgUrl: "https://www.momentshwork.com/momentimg/ds/work/img1.jpg",
              copy_title: "BE-KIND猫头海报",
              copy_time: "Oct. 16. 2020",
              id: 0,
              isState: false
            },

          ],
        },
        {
          code: true,
          name: "品牌形象",
          us_name: "BRAND",
          min_title: "商业与艺术结合，助力品牌深入人心",
          datalist: [
            {
              imgUrl: "https://www.momentshwork.com/momentimg/pp/work/img3.gif",
              copy_title: "妙脆角新品主视觉",
              copy_time: "Feb.03.2023",
              id: 2,
              isState: false
            },
            {
              imgUrl: "https://www.momentshwork.com/momentimg/pp/work/img2.jpg",
              copy_title: "Fairy Cafe(更新中)",
              copy_time: "Aug.12.2015",
              id: 1,
              isState: true
            },
            {
              imgUrl: "https://www.momentshwork.com/momentimg/pp/work/img1.gif",
              copy_title: "养个蛙品牌视觉形象",
              copy_time: "May. 28. 2018",
              id: 0,
              isState: false
            },

          ],
        },
        {
          code: true,
          name: "包装设计",
          us_name: "PACKING",
          min_title: "以产品战略角度出发，通过创意设计传播，帮助提升销售转化率",
          datalist: [
            {
              imgUrl: "https://www.momentshwork.com/momentimg/lh/work/img5.jpg",
              copy_title: "奇多奇乐无穷礼盒(更新中)",
              copy_time: "May.10.2023",
              id: 4,
              isState: true
            },
            {
              imgUrl: "https://www.momentshwork.com/momentimg/lh/work/img4.jpg",
              copy_title: "桂格5养系列每日麦片礼盒&PDQ(更新中)",
              copy_time: "Feb.02.2023",
              id: 3,
              isState: true
            },
            {
              imgUrl: "https://www.momentshwork.com/momentimg/lh/work/img3.jpg",
              copy_title: "乐事春季限定礼盒",
              copy_time: "January. 09. 2023",
              id: 2,
              isState: false
            },
            {
              imgUrl: "https://www.momentshwork.com/momentimg/lh/work/img2.jpg",
              copy_title: "乐事京东CNY礼盒",
              copy_time: "September. 20. 2022",
              id: 1,
              isState: false
            },
            {
              imgUrl: "https://www.momentshwork.com/momentimg/lh/work/img1.jpg",
              copy_title: "好奇金钻礼盒",
              copy_time: "June. 04. 2019",
              id: 0,
              isState: false
            },

          ],
        },
        {
          code: false,
          name: "短视频",
          us_name: "VIDEO",
          min_title:
            "从平面到三维到动效的结合，能更好的服务于商业目的达到多维度的内容呈现",
          datalist: [
            {
              imgUrl:
                "https://www.momentshwork.com/momentimg/dsp/work/img1.jpg",
              copy_title: "前方正在施工",
              copy_time: "",
              id: 0,
              isState: false
            },
          ],
        },
        {
          code: true,
          name: "商业拍摄",
          us_name: "PHOTOGRAPH",
          min_title: "商业活动、品牌传播的基石，让产品于视觉感官中脱颖而出",
          datalist: [

            {
              imgUrl:
                "https://www.momentshwork.com/momentimg/syps/work/img1.jpg",
              copy_title: "禾泱泱海苔碎拌饭料",
              copy_time: "Oct. 23. 2020",
              id: 0,
              isState: false
            },
            {
              imgUrl:
                "https://www.momentshwork.com/momentimg/syps/work/img2.jpg",
              copy_title: "禾泱泱夹心米饼",
              copy_time: "Dec. 08. 2021",
              id: 1,
              isState: false
            },
          ],
        },
      ],
    };
  },
  methods: {
    goTolink(workData) {
      if (!workData.isState) {
        var AppDom = document.querySelector("#app");
        AppDom.classList.add("fadeup_anime");

        let that = this;
        let timeblackAnime = setTimeout(() => {
          that.$router.push({
            name: "Case",
            query: { id: workData.id },
          });
          AppDom.classList.remove("fadeup_anime");
          clearTimeout(timeblackAnime);
        }, 500);
      }
      else {
        return false
      }


    },
    loading() {
      let that = this;
      document.onreadystatechange = subSomething; //当页面加载状态改变的时候执行这个方法.
      function subSomething() {
        console.log(document.readyState);
        if (document.readyState == "complete") {
          console.log("资源加载完成 展示组件");
          console.log(9999);
          // setTimeout(() => {
          //   document
          //     .querySelector("html")
          //     .setAttribute("style", "overflow:visible; position: static");
          // }, 500);
          setTimeout(() => {
            that.isload = false;
            new WOW({
              boxClass: "wow",
              animateClass: "animated",
              offset: 200,
              mobile: true,
              live: false,
            }).init();
          }, 500);
        }
      }
      subSomething();
    },


  },
  mounted() {

    let that = this;
    that.li_lens = 'li_len' + that.t_Title[that.th_id].datalist.length
    that.$parent.navAnimation = true;
    document.querySelector("#app").style.cssText = "background:none";
    that.$nextTick(() => {
      that.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0);
      });
      that.loading();
    });
  },
  beforeCreate() {
    // document
    //   .querySelector("html")
    //   .setAttribute("style", "overflow:hidden; position: fixed");
  },
};
</script>
<style lang="less" scoped>
.app {
  padding-top: 155px;
  background-color: #f9e14c;

  .setwh(@width: 0, @height: 0) {
    width: @width / @vw;
    height: @height / @vw;
  }

  @vw: 1920/100vw;

  * {
    padding: 0;
    margin: 0;
  }

  .centerbox,
  .error {
    width: 100%;
    padding: 155px 45px 155px 45px;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 100vh;

    .top_head {
      .us_title {
        P {
          font-size: 30px;
          font-family: harmonyBlack;
          text-align: right;
          margin-top: -120px;
        }
      }

      .ch_title {
        P {
          font-size: 60px;
          font-family: harmonyMedium;
          text-align: left;
          margin-top: 85px;
        }
      }

      .copy_text {
        P {
          font-size: 28px;
          font-family: harmonyLight;
          text-align: left;
          margin-top: 20px;

        }
      }

      .divider {
        width: 100%;
        border-top: 1px solid #000;
        margin-top: 40px;
      }
    }

    .listbox {
      li {
        overflow: hidden;
        margin-top: 85px;

        .topimg {
          width: 666px;
          height: 932px;
          background-color: #fff;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .b_title {
          margin-top: 30px;

          .big_title {
            font-size: 48px;
            font-family: harmonyMedium;
          }

          .time_text {
            margin-top: 6px;
            position: relative;

            p {
              font-size: 24px;
              font-family: harmonyLight;
              color: #948529;
            }

            .link_btn {
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
        }
      }
    }
  }

  .loader {
    width: 100%;
    height: 0;
    position: fixed;
    top: 0;
    z-index: 9999;
    overflow: hidden;
    background-color: #f9e14c;
  }

  .active_parent {
    transition: all 0.3s;
    height: 100%;
  }
}
</style>
