import Vue from 'vue'
import VueRouter from 'vue-router'

// const Home = () =>
//     import ('@/views/Home/index.vue')
// const Work = () =>
//     import ('@/views/Work/index.vue')
// const Case = () =>
//     import ('@/views/Case/index.vue')
import Home from '@/views/Home/index.vue'
import Work from '@/views/Work/index.vue'
import Case from '@/views/Case/index.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        meta: {
            index: 0
        },
        component: Home,

    },
    {
        path: '/work/:business',
        name: 'Work',
        meta: {
            index: 1
        },
        component: Work,
    }, {
        path: '/case/:business',
        name: 'Case',
        meta: {
            index: 2
        },
        component: Case
    }
]

const router = new VueRouter({

    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },

});


const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)

}
Vue.use(VueRouter);
export default router