<template>
  <div class="nav_top" :class="{ active: navisOpen }">
    <transition name="fade">
      <div class="c-nav_background" v-show="navisOpen"></div>
    </transition>
    <div class="foot_text">
      <span>COPYRIGHT © MOMENT INC. ALL RIGHTS RESERVED.</span>
    </div>
    <div class="black_box"></div>
    <div class="top_head">
      <div class="l_logo" @click="toLink($router.push('/'), (navisOpen = false), toTop())">
        <img src="./img/nav_logo1.png" />
        <img src="./img/nav_logo2.png" />
      </div>
      <div class="r_nav_btn" @click="openNav">
        <span :class="{ t_active: navisOpen }"></span>
        <span :class="{ b_active: navisOpen }"></span>
        <!-- <div class="c-header"></div> -->
      </div>
    </div>
    <div class="an_nav">
      <ul :class="{ li_active: navisOpen }" class="c-nav_list">
        <li class="zhuye_list" @click="$router.push('/'), (navisOpen = false)">
          <p>主页</p>
        </li>
        <li class="gongzuo_list" :class="{ active: navtoisOpen }">
          <p class="list_btn" @click="toopenNav">工作 <span>13</span></p>

          <ul class="gz_open">
            <li @click="
              toHome(
                $router.push({
                  name: 'Work',
                  params: { business: 'ds' },
                  query: { id: 0 },
                }),
                (navisOpen = false)
              )
              ">
              电商设计
            </li>
            <li @click="
              toLink(
                $router.push({
                  name: 'Work',
                  params: { business: 'pp' },
                  query: { id: 1 },
                }),
                (navisOpen = false)
              )
              ">
              品牌形象
            </li>
            <li @click="
              toLink(
                $router.push({
                  name: 'Work',
                  params: { business: 'lh' },
                  query: { id: 2 },
                }),
                (navisOpen = false)
              )
              ">
              包装设计
            </li>
            <li @click="
              toLink(
                $router.push({
                  name: 'Work',
                  params: { business: 'video' },
                  query: { id: 3 },
                }),
                (navisOpen = false)
              )
              ">
              短视频
            </li>
            <li @click="
              toLink(
                $router.push({
                  name: 'Work',
                  params: { business: 'syps' },
                  query: { id: 4 },
                }),
                (navisOpen = false)
              )
              ">
              商业摄影
            </li>
          </ul>
        </li>
        <li class="td_list">
          <p>团队 <span>(正在施工)</span></p>
        </li>
        <li class="lx_list" @click="toBottom(), (navisOpen = false)">
          <p>联系</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navisOpen: false,
      navtoisOpen: false,
      shuju: 0,
      gotop: false,
    };
  },
  methods: {
    toHome() { },
    openNav() {
      let anmintn = document.querySelector(".gongzuo_list");
      this.navisOpen = !this.navisOpen;
      if (this.navisOpen) {
        anmintn.style.transitionDelay = "0.52s";
      } else {
        anmintn.style.transitionDelay = "0s";
      }
    },
    toopenNav() {
      let anmintn = document.querySelector(".gongzuo_list");
      anmintn.style.transitionDelay = "0s";
      this.navtoisOpen = !this.navtoisOpen;
    },
    toLink() { },
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if (this.$route.path == "/") {
        const timeTop = setInterval(() => {
          document.body.scrollTop =
            document.documentElement.scrollTop =
            top -=
            50;
          if (top <= 0) {
            clearInterval(timeTop);
          }
        }, 10);
      }
    },
    toBottom() {
      this.$nextTick(() => {
        window.scrollTo(0, 50000);
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transform: scaleX(1);
  transform-origin: center left;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1) 0.2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  transform: scaleX(0);
  transition-delay: 0s;
}

.nav_top {
  overflow: hidden;
  transition: 0.2s;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 0 45px;
  box-sizing: border-box;
  height: 155px;

  .black_box {
    width: 100%;
    height: 155px;
    position: absolute;
    top: -155px;
    left: 0;
    background-color: #000;
    transition: 0.3s;
  }

  .c-nav_background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #000;
    z-index: -1;
  }

  .foot_text {
    position: absolute;
    top: 0;
    left: 0;
    font-family: harmonyLight;
    font-size: 15px;
    width: 100vw;
    height: 100vh;
    text-align: center;
    color: #9a9a9a;

    span {
      display: block;
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 50px;
      opacity: 0;
      transform: translateY(150px);
    }
  }

  .top_head {
    width: 100%;
    height: 155px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l_logo {
      position: relative;
      z-index: 999;

      :nth-child(2) {
        display: none;
      }

      :nth-child(1) {
        display: block;
      }
    }
  }

  .r_nav_btn {
    position: relative;
    height: 55px;
    width: 55px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    span {
      position: relative;
      z-index: 99;
      display: block;
      width: 100%;
      height: 5px;
      background-color: #000;
      transition: 0.3s ease;
    }

    // .c-header{
    //   width: 80px;
    //   height: 80px;
    //   position: absolute;
    //   margin-left: -40px;
    //   margin-top: -40px;
    //   top: 50%;
    //   left: 50%;
    //   background-color: #ffffff;
    //   border-radius: 50%;
    // }
    .t_active {
      background-color: #ffffff;
      transform: translateY(14px) rotate(45deg);
    }

    .b_active {
      background-color: #ffffff;
      transform: translateY(-14px) rotate(-45deg);
    }
  }

  .an_nav {
    position: absolute;
    width: 100%;
    height: 80%;
    padding: 35px 55px;
    box-sizing: border-box;
    position: relative;

    .c-nav_list {
      li {
        overflow: hidden;

        p {
          transition: transform 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53),
            opacity 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        }
      }

      > ::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 1px;
        background-color: #fff;
        transform: scaleX(0);
        transform-origin: center left;
        transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
      }
    }

    ul {
      li {
        // transition: 0.3s;
        color: rgb(184, 182, 182);
        font-size: 30px;
        height: 60px;
        line-height: 60px;
        font-family: harmonyLight;
        position: relative;

        a {
          color: #b8b6b6;
          font-size: 34px;
          height: 60px;
          line-height: 60px;
          font-family: harmonyRegular;
        }

        span {
          position: absolute;
          top: 35px;
          left: 125px;
          font-size: 20px;
          font-family: harmonyMedium;
          line-height: 0;
        }
      }

      .zhuye_list {
        height: 140px;
        line-height: 140px;
        font-family: harmonyMedium;
        font-size: 50px;

        p {
          opacity: 0;
          transform: translateY(150px);
        }
      }

      .gongzuo_list {
        transition-duration: 0.3s;
        height: 140px;
        font-size: 50px;
        font-family: harmonyMedium;
        position: relative;
        overflow: hidden;

        p {
          opacity: 0;
          line-height: 140px;
          transform: translateY(150px);
        }

        .gz_open {
          opacity: 0;
          transform: translateY(150px);
          transition-duration: 0.52s;
        }
      }

      .active {
        height: 481px;

        .list_btn {
          color: #fff;
        }
      }

      .td_list {
        position: relative;
        height: 140px;
        font-size: 50px;
        font-family: harmonyMedium;
        line-height: 140px;

        p {
          opacity: 0;
          transform: translateY(150px);
        }
      }

      .lx_list {
        position: relative;
        font-size: 50px;
        font-family: harmonyMedium;
        height: 140px;
        line-height: 140px;

        p {
          opacity: 0;
          transform: translateY(150px);
        }
      }
    }

    .li_active {
      .zhuye_list {
        p {
          transition-delay: 0.46s;
          opacity: 1;
          transform: none;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }

      > ::after {
        transform: scaleX(1);
        transition-delay: 0.6s;
      }

      .gongzuo_list {
        p {
          transition-delay: 0.52s;
          opacity: 1;
          transform: none;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .gz_open {
          transition-delay: 0.52s;
          transition-duration: 0.52s;
          opacity: 1;
          transform: none;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }

      .td_list {
        p {
          transition-delay: 0.58s;
          opacity: 1;
          transform: none;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }

      .lx_list {
        p {
          transition-delay: 0.64s;
          opacity: 1;
          transform: none;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

.active {
  height: 100%;

  .top_head {
    .l_logo {
      :nth-child(1) {
        display: none !important;
      }

      :nth-child(2) {
        display: block !important;
      }
    }
  }

  .foot_text {
    span {
      transition-delay: 0.46s;
      transition-duration: 0.52s;
      opacity: 1;
      transform: none;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
</style>