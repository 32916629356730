<template>
  <div class="app">
    <div class="head ">
      <section :class="{ active_parent: mountedOk }" class="anime_one">
        <img v-if="!isPC_mobile" src="@/img/head_biglog_img.png" class="head_biglog_img" alt="" />
        <img v-if="isPC_mobile" src="@/img/pc_head_biglog_img.png" class="head_biglog_img" alt="" />
        <div v-if="!isPC_mobile" class="head_us_img">
          <img src="@/img/head_us_img.png" class="_delay-1 _parent" alt="" />
        </div>
        <div v-if="isPC_mobile" class="pc_head_us_img">

        </div>
        <div class="head_ch_img">
          <img v-if="!isPC_mobile" src="@/img/head_ch_img.png" class="_delay-0 _parent1" alt="" />
          <p v-if="isPC_mobile">用创意<br>放大<br>你的价值</p>
        </div>
        <div class="head_down_img">
          <img v-if="!isPC_mobile" src="@/img/head_down_img.png" class="_delay-1 _parent" alt="" />
          <img v-if="isPC_mobile" src="@/img/pc_head_down_img.png" class="_delay-1 _parent" alt="" />
        </div>
        <div class="head_mintext_img">
          <img v-if="!isPC_mobile" src="@/img/head_mintext_img.png" class="_delay-2 _parent" alt="" />
          <p v-if="isPC_mobile">WITH CREATIVITY<br>
            AMPLIFY<br>
            TOUR VALUE</p>
        </div>
        <div v-if="!isPC_mobile" class="head_foot_text">
          <p>Moment（秒珍）是一家以设计为主导的创意公司</p>
          <p>
            由几位设计师合伙创立，致力于从客户角度理解设计，将高品质设计应用于商业实效。<br />
            我们的业务范围涵盖线上线下，尤其擅长电商设计，品牌形象、产品包装设计，短视频制作、商业拍摄等。
          </p>
        </div>
        <div v-if="isPC_mobile" class="head_foot_text">
          <p>Moment（秒珍）是一家以设计为主导的创意公司，由几位设计师合伙创立，致力于从客户角度理解设计，将高品质设计应用于商业实效。<br>
            我们的业务范围涵盖线上线下，尤其擅长电商设计，品牌形象、产品包装设计，短视频制作、商业拍摄等。
          </p>
        </div>
        <div v-if="!isPC_mobile" class="m_logos">
          <div class="head_logo_01">
            <img src="@/img/logo_icon_01.png" alt="" />
          </div>
          <div class="head_logo_02">
            <img src="@/img/logo_icon_02.png" alt="" />
          </div>
          <div class="head_logo_03">
            <img src="@/img/logo_icon_03.png" alt="" />
          </div>
          <div class="head_logo_04">
            <img src="@/img/logo_icon_04.png" alt="" />
          </div>
          <div class="head_logo_05">
            <img src="@/img/logo_icon_05.png" alt="" />
          </div>
          <div class="head_logo_06">
            <img src="@/img/logo_icon_06.png" alt="" />
          </div>
          <div class="head_logo_08">
            <img src="@/img/logo_icon_08.png" alt="" />
          </div>
          <div class="head_logo_09">
            <img src="@/img/logo_icon_09.png" alt="" />
          </div>
        </div>
      </section>
      <div v-if="isPC_mobile" class="pc_logos">
        <div class="head_logo_01">
          <img src="@/img/pc_logo_icon_01.png" alt="" />
        </div>
        <div class="head_logo_02">
          <img src="@/img/pc_logo_icon_02.png" alt="" />
        </div>
        <div class="head_logo_03">
          <img src="@/img/pc_logo_icon_03.png" alt="" />
        </div>
        <div class="head_logo_04">
          <img src="@/img/pc_logo_icon_04.png" alt="" />
        </div>
        <div class="head_logo_05">
          <img src="@/img/pc_logo_icon_05.png" alt="" />
        </div>
        <div class="head_logo_06">
          <img src="@/img/pc_logo_icon_06.png" alt="" />
        </div>
        <div class="head_logo_08">
          <img src="@/img/pc_logo_icon_08.png" alt="" />
        </div>
        <div class="head_logo_09">
          <img src="@/img/pc_logo_icon_09.png" alt="" />
        </div>
      </div>
    </div>
    <div v-if="!isPC_mobile" class="work_list">
      <ul>
        <li v-for="idx in work" :key="idx.id" @click="gotolink(idx)">
          <img :src="idx.img" class="copy_img wow pulse" data-wow-duration="1.5s" alt="" />
          <div class="t_title">
            <h3 class="wow slideInUp" data-wow-duration=".5s" data-wow-delay=".2s">
              {{ idx.title }}
            </h3>
            <div class="_icon wow slideInUp" data-wow-delay=".3s" data-wow-duration=".5s"></div>
            <h1 class="us_title wow slideInUp" data-wow-delay=".4s" data-wow-duration=".5s">
              {{ idx.ustitle }}
            </h1>
          </div>
          <div class="golink_img">
            <img data-wow-delay=".5s" data-wow-duration=".5s" class="wow slideInUp" src="@/img/link_btn.png" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <div v-if="isPC_mobile" iuu="ccc" class="work_list">
      <ul>
        <li v-for="idx in work" :key="idx.id" @click="gotolink(idx)">
          <img :src="idx.pc_img" class="copy_img wow pulse" data-wow-duration="1.5s" alt="" />
          <div class="t_title">
            <h3 class="wow slideInUp" data-wow-duration=".5s" data-wow-delay=".2s">
              {{ idx.title }}
            </h3>
            <div class="_icon wow slideInUp" data-wow-delay=".3s" data-wow-duration=".5s"></div>
            <h1 class="us_title wow slideInUp" data-wow-delay=".4s" data-wow-duration=".5s">
              {{ idx.ustitle }}
            </h1>
          </div>
          <div class="golink_img">
            <img data-wow-delay=".5s" data-wow-duration=".5s" class="wow slideInUp" src="@/img/link_btn.png" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <div v-if="isload">
      <div>
        <div class="loader_bg" :class="{ ready: anime }" @touchmove.prevent></div>
      </div>
      <div class="loading animated fadeInUp" :class="{ fadeOutUp: anime }">
        <div class="logo_wrap">
          <img src="@/img/loading_logo.png" alt="" />
        </div>
        <img src="@/img/loading_txt.png" class="loading_txt" />
      </div>
    </div>
  </div>
</template>

<script >

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
import { WOW } from "wowjs";
export default {

  props: {
    isPC_mobile: Boolean,
    nav_ok: Boolean
  },
  data() {
    return {
      mountedOk: false,
      isload: true,
      anime: false,
      blackAnime: false,
      nav_ture: this.nav_ok,

      work: [
        {
          name: "ds",
          title: "电商设计",
          ustitle: "E-COMMERCE",
          img: "https://www.momentshwork.com/momentimg/ds/home_img.jpg",
          pc_img: "https://www.momentshwork.com/momentimg/ds/pc_home_img.jpg",
          id: 0,
        },
        {
          name: "pp",
          title: "品牌形象",
          ustitle: "BRAND",
          img: "https://www.momentshwork.com/momentimg/pp/home_img.jpg",
          pc_img: "https://www.momentshwork.com/momentimg/pp/pc_home_img.jpg",
          id: 1,
        },
        {
          name: "lh",
          title: "包装设计",
          ustitle: "PACKING",
          img: "https://www.momentshwork.com/momentimg/lh/home_img.jpg",
          pc_img: "https://www.momentshwork.com/momentimg/lh/pc_home_img.jpg",
          id: 2,
        },

        {
          name: "syps",
          title: "商业拍摄",
          ustitle: "PHOTOGRAPH",
          img: "https://www.momentshwork.com/momentimg/syps/home_img.jpg",
          pc_img: "https://www.momentshwork.com/momentimg/syps/pc_home_img.jpg",
          id: 4,
        },
        {
          name: "dsp",
          title: "短视频",
          ustitle: "VIDEO",
          img: "https://www.momentshwork.com/momentimg/dsp/home_img.jpg",
          pc_img: "https://www.momentshwork.com/momentimg/dsp/pc_home_img.jpg",
          id: 3,
        },
      ],
    };
  },
  methods: {

    gotolink(idx) {
      var AppDom = document.querySelector("#app");
      AppDom.classList.add("fadeup_anime");
      let that = this;
      let timeblackAnime = setTimeout(() => {
        that.$router.push({
          name: "Work",
          params: { business: idx.name },
          query: { id: idx.id },
        });
        AppDom.classList.remove("fadeup_anime");
        clearTimeout(timeblackAnime);
      }, 500);
    },
    initSticky() {
      // 
      this.$nextTick(() => {
        window.addEventListener("scroll", (e) => {
          this.thatOffsetTop = this.getScrollTop(); // 滚动条距离顶部高度
          if (this.thatOffsetTop >= document.querySelector(".head ").clientHeight) {
            this.$parent.navAnimation = true;
          } else {
            this.$parent.navAnimation = false;
          }
        });
      });
      // console.log("this.navHeight :", this.navHeight);

    },
    getScrollTop() {
      var scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      return scrollTop;
    },

    loading() {
      let that = this;
      console.log(that.isload);
      document.onreadystatechange = subSomething; //当页面加载状态改变的时候执行这个方法.

      function subSomething() {
        console.log(document.readyState);
        if (document.readyState == "complete") {
          console.log("资源加载完成 展示组件");
          console.log(9999);
          gsap.to(".head_biglog_img", {
            scrollTrigger: {
              scrub: 1,
            },
            scale: 50,
            transformOrigin: "center top",
          });
          gsap.to(" .head_us_img", {
            scrollTrigger: {
              scrub: 1,
            },
            y: -2000,
          });
          gsap.to(".head_ch_img", {
            scrollTrigger: {
              scrub: 1,
            },
            y: -3000,
          });
          gsap.to(".head_down_img", {
            scrollTrigger: {
              scrub: 1,
            },
            y: -4500,
          });
          gsap.to(".head_mintext_img", {
            scrollTrigger: {
              scrub: 1,
            },
            y: -4500,
          });
          gsap.to(".head_foot_text", {
            scrollTrigger: {
              scrub: 1,
            },
            y: -4250,
            animationTimingFunction: "ease-out"

          });
          gsap.from(".m_logos .head_logo_02", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 400,
              end: 950,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".m_logos .head_logo_04", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 440,
              end: 950,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".m_logos .head_logo_05", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 480,
              end: 950,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".m_logos .head_logo_03", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 520,
              end: 950,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".m_logos .head_logo_06", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 560,
              end: 950,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".m_logos .head_logo_09", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 600,
              end: 950,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          // gsap.from(".head_logo_07", {
          //   scrollTrigger: {
          //     immediateRender: false,
          //     scrub: 1,
          //     start: 850,
          //     end: 1000,
          //   },
          //   opacity: 0,
          //   scale: 0,
          //   xPercent: -50, left: "50%", top: "70%",
          // });
          gsap.from(".m_logos .head_logo_01", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 640,
              end: 950,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".m_logos .head_logo_08", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 680,
              end: 950,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });


          gsap.from(".pc_logos .head_logo_02", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 200,
              end: 550,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".pc_logos .head_logo_04", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 230,
              end: 550,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".pc_logos .head_logo_05", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 260,
              end: 550,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".pc_logos .head_logo_03", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 290,
              end: 550,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".pc_logos .head_logo_06", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 320,
              end: 550,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".pc_logos .head_logo_09", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 350,
              end: 550,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          // gsap.from(".head_logo_07", {
          //   scrollTrigger: {
          //     immediateRender: false,
          //     scrub: 1,
          //     start: 850,
          //     end: 1000,
          //   },
          //   opacity: 0,
          //   scale: 0,
          //   xPercent: -50, left: "50%", top: "70%",
          // });
          gsap.from(".pc_logos .head_logo_01", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 380,
              end: 550,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });
          gsap.from(".pc_logos .head_logo_08", {
            scrollTrigger: {
              immediateRender: false,
              scrub: 1,
              start: 410,
              end: 550,
            },
            opacity: 0,
            scale: 0,
            xPercent: -50, left: "50%", bottom: "10%",
          });

          let timeOne = setTimeout(() => {
            that.anime = true;
            that.mountedOk = true;

            let wow = new WOW({
              boxClass: "wow", //需要执行动画的元素的 class
              animateClass: "animated", //animation.css 动画的 class
              offset: 50, //距离可视区域多少开始执行动画
              mobile: true, //是否在移动设备上执行动画
              live: true, //异步加载的内容是否有效
            });
            clearTimeout(timeOne);
            wow.init();
            
          }, 2000);

          // let timeTwo = setTimeout(() => {
          //   document
          //     .querySelector("html")
          //     .setAttribute("style", "overflow:visible; position: static");
          //   that.isload = false;
          //   that.mountedOk = true;
          //   clearTimeout(timeTwo);
          // }, 2000);
        }
      }
      subSomething();

    },
  },
  mounted() {
    let that = this;
    that.isPC_mobile = that.$props.isPC_mobile
    that.$parent.navAnimation = false;
    document.querySelector("#app").style.cssText = "background:#fae14b";
    // document.querySelector("html").setAttribute("style", "overflow:hidden;");
    that.$nextTick(() => {
      that.loading();
      that.initSticky();
    });
    setTimeout(() => {

      this.listData.push()
      // listData length无变化，仅仅是属性变更，手动调用下组件内部的reset方法
      this.$refs.seamlessScroll.reset()
    }, 2000);
  },
};
</script>
<style lang="less" scoped>
.app {
  background-color: #fae14b;

  .loader_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    transition: 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
    -webkit-transform-origin: top;
    transform-origin: top;
    cursor: wait;
    will-change: transform !important;
    z-index: 5000;
  }

  .fadeOutUp {
    animation-duration: 300ms;
    display: none !important;
  }



  .loading {
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all;
    transition-duration: 100ms;
    z-index: 999999;

    .logo_wrap {
      width: 124px;
      height: 87px;
      margin-bottom: 40px;
    }

    .loading_txt {
      display: block;
    }
  }

  .ready {
    top: -100%;
  }

  .head {
    width: 100%;
    height: 150vh;
    // background-color: #fae14b;


    .anime_one {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;

      .head_biglog_img {
        position: absolute;
        left: 0;
        top: 255px;
        right: 0;
        bottom: 0;
        margin: auto;
        /* 有了这个就自动居中了 */
      }

      .head_us_img {
        position: absolute;
        top: 228px;
      }

      .head_ch_img {
        position: absolute;
        top: 315px;
        right: 100px;
      }

      .head_down_img {
        position: absolute;
        bottom: 135px;
        left: 50px;
      }

      .head_mintext_img {
        position: absolute;
        bottom: 135px;
        right: 220px;
      }

      .head_foot_text {
        padding: 0 40px;
        position: absolute;
        bottom: -175px;
        text-align: justify;
        overflow: hidden;
        font-size: 26px;
        font-family: harmonyLight;
      }

      .head_logo_01 {
        width: 212px;
        position: absolute;
        left: 100px;
        bottom: 733px;
        opacity: 1;

        img {
          width: 100%;
        }
      }

      .head_logo_02 {
        width: 189px;
        position: absolute;
        left: 467px;
        bottom: 171px;
        opacity: 1;

        img {
          width: 100%;
        }
      }

      .head_logo_03 {
        width: 205px;
        position: absolute;
        left: 102px;
        bottom: 534px;
        opacity: 1;

        img {
          width: 100%;
        }
      }

      .head_logo_04 {
        width: 157px;
        position: absolute;
        left: 123px;
        bottom: 335px;
        opacity: 1;

        img {
          width: 100%;
        }
      }

      .head_logo_05 {
        width: 194px;
        position: absolute;
        left: 468px;
        bottom: 522px;
        opacity: 1;

        img {
          width: 100%;
        }
      }

      .head_logo_06 {
        width: 180px;
        position: absolute;
        left: 112px;
        bottom: 160px;
        opacity: 1;

        img {
          width: 100%;
        }
      }

      // .head_logo_07 {
      //   width: 106px;
      //   height: 53px;
      //   position: absolute;
      //   left: 92px;
      //   top: 870px;
      //   opacity: 1;

      //   img {
      //     width: 100%;
      //   }
      // }

      .head_logo_08 {
        width: 272px;
        position: absolute;
        left: 412px;
        bottom: 731px;
        opacity: 1;

        img {
          width: 100%;
        }
      }

      .head_logo_09 {
        width: 207px;
        position: absolute;
        left: 458px;
        bottom: 340px;
        opacity: 1;

        img {
          width: 100%;
        }
      }
    }
  }

  .work_list {
    height: auto;

    ul {
      li {
        .copy_img {
          visibility: visible !important;
        }

        position: relative;
        overflow: hidden;

        .t_title {
          position: absolute;
          top: 95px;
          left: 45px;

          h3 {
            font-size: 46px;
            font-family: harmonyMedium;
            color: #ffffff;
          }

          ._icon {
            width: 37px;
            height: 3px;
            background-color: #ffffff;
            margin: 30px 0 40px 5px;
          }

          .us_title {
            font-size: 68px;
            font-family: harmonyBlack;
            color: #fff;
            margin-left: 3px;
          }
        }

        .golink_img {
          position: absolute;
          top: 447px;
          left: 60px;
        }
      }
    }
  }

  .work_list_anime {
    opacity: 0;
    transform: translateY(-84px);
    transition: opacity 0.9s cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  ._delay-1 {
    opacity: 0;
    transform: translateY(-84px);
    transition: opacity 0.9s cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  ._delay-0 {
    opacity: 0;
    transform: translateY(-90px);
    transition: opacity 0.9s cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  ._delay-2 {
    opacity: 0;
    transform: translateY(84px);
    transition: opacity 0.9s cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .active_parent {
    ._parent {
      opacity: 1;
      transform: none;
      transition-delay: 0.4s;
    }

    ._parent1 {
      opacity: 1;
      transform: none;
      transition-delay: 0.6s;
    }
  }
}
</style>